import { throttle } from 'lodash';

const pageTopBtn = document.querySelector('.js-pageTopBtn');
const floating = document.querySelector('.js-floating');

function pageTop() {
  window.addEventListener(
    'scroll',
    _.throttle(
      (e) => {
        scrollBtnView();
        return false;
      },
      100,
      { trailing: true, leading: true }
    )
  );

  // ページ読み込み時にスクロールトップボタンがkvより下にあれば表示
  window.addEventListener('load', () => {
    const scroll = window.scrollY;
    if (scroll > 200) {
      pageTopBtn.classList.add('is-view');
    } else {
      pageTopBtn.classList.remove('is-view');
    }
  });
}

// 間引きしたい処理
function scrollBtnView() {
  const scroll = window.scrollY;

  // スクロール量がkvの高さを超えたらページトップボタンを表示
  if (scroll > 200) {
    pageTopBtn.classList.add('is-view');
  } else {
    pageTopBtn.classList.remove('is-view');
  }
}

function pageTop2() {
  window.addEventListener(
    'scroll',
    _.throttle(
      (e) => {
        scrollBtnView2();
        return false;
      },
      100,
      { trailing: true, leading: true }
    )
  );

  // ページ読み込み時にスクロールトップボタンがkvより下にあれば表示
  window.addEventListener('load', () => {
    if (!floating) {
      return;
    }

    const scroll = window.scrollY;

    if (scroll > 200) {
      floating.classList.add('is-view');
    } else {
      floating.classList.remove('is-view');
    }
  });
}

// 間引きしたい処理
function scrollBtnView2() {
  if (!floating) {
    return;
  }

  const scroll = window.scrollY;

  // スクロール量がkvの高さを超えたらページトップボタンを表示
  if (scroll > 200) {
    floating.classList.add('is-view');
  } else {
    floating.classList.remove('is-view');
  }
}

pageTop();
pageTop2();
